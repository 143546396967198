import React from 'react'

const Product3 = () => {
    return (
        <div className='flex flex-col justify-center items-center border border-1 border-black w-fit p-3 text-center space-y-5'>
            <img src={'/assets/images/Picture4.png'} className="w-96" alt='' />
            <p className='text-2xl text-[#A68671]'>Pistache</p>
            <p className='w-[23rem] font-ramillas text-xl'>Essayez notre Amlou de pistache avec votre pain préféré.</p>
            <a href='#' className='font-ramillas text-xl underline'>ACHETEZ SANS PLUS ATTENDRE</a>
        </div>
    )
}

export default Product3