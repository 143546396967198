import React from 'react';
import HeroSection from '../components/hero_section';
import NosProduits from '../components/nosproduits';
import Footer from '../components/footer';

const Home = () => {
  return (
    <div>
      <HeroSection />
      <NosProduits />
      <Footer/>
    </div>

  );
};
export default Home;
