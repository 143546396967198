import React from 'react';

const Footer = () => {
    return (
        <div className='relative'>
            <img src={'/assets/images/Picture5.png'} className="z-0 absolute inset-0 w-full h-full object-cover" alt='' />
            <div className='z-20 relative text-white h-screen flex flex-col justify-center items-center px-4 md:px-8 lg:px-16'>
                <p className='text-4xl md:text-6xl font-knickerbockers text-center mb-16'>Le bonheur dans chaque bouchée</p>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-center font-ramillas text-xl md:text-2xl font-bold'>
                    <div className='space-y-5'>
                        <p>EMPLACEMENT</p>
                        <p>SALLE DES CONGRES - UNIVERSIAPOLIS</p>
                    </div>
                    <div className='space-y-5'>
                        <p>Adresse e-mail</p>
                        <p>amloud.contact@gmail.com</p>
                    </div>
                    <div className='space-y-5'>
                        <p>numéro de téléphone</p>
                        <p>+212 5 28820553</p>
                    </div>
                </div>
                <div className='mt-12'>
                    <p className='text-white font-ramillas text-4xl text-center md:text-left mb-6'>RESEAUX SOCIAUX</p>
                    <div className='flex justify-center md:justify-center'>
                        <a href='https://web.facebook.com/profile.php?id=61558967260420' className='mr-4'><img src={'/assets/images/fa.png'} className='w-8' alt='' /></a>
                        <a href='https://www.instagram.com/amloud2024/' className='mr-4'><img src={'/assets/images/in.png'} className='w-8' alt='' /></a>
                        <a href='https://twitter.com/Amloudoff'><img src={'/assets/images/tw.png'} className='w-8' alt='' /></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
