import React from 'react'

const Product1 = () => {
    return (
        <div className='flex flex-col justify-center items-center border border-1 border-black w-fit p-3 text-center space-y-5'>
            <img src={'/assets/images/Picture1.png'} className="w-96" alt='' />
            <p className='text-2xl text-[#A68671]'>Amandes</p>
            <p className='w-80 font-ramillas text-xl'>Savourez les bienfaits des amandes grillées sous forme d’Amlou.</p>
            <a href='#' className='font-ramillas text-xl underline'>ACHETEZ SANS PLUS ATTENDRE</a>
        </div>
    )
}

export default Product1