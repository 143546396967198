import React from 'react'

const HeroSection = () => {
  return (
    <div className="min-h-screen flex justify-center items-center hero">
      <img src={'/assets/images/hero_section.png'} className="z-0 w-full h-full absolute inset-0 object-cover" alt='' />
      <div className='text-center relative flex flex-col items-center space-y-12'>
        <div>
          <img src={'/assets/images/Picture2.png'} className="w-80 md:w-96" alt='' />
          <p className='text-4xl md:text-8xl text-[#633110] font-bold font-bosk'>AMLOUD</p>
        </div>
        <div>
          <p className='text-3xl md:text-9xl text-white font-knickerbockers'>UNE EXPERIENCE GUSTATIVE QUI SE</p>
          <p className='text-3xl md:text-9xl text-white font-knickerbockers'>DEMARQUE!</p>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
