import Product1 from './products/product1';
import Product2 from './products/product2';
import Product3 from './products/product3';

import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

const NosProduits = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const triggerOffset = 600;
            setIsVisible(scrollTop > triggerOffset);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const springProps = useSpring({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0)' : 'translateY(100px)',
    });

    return (
        <div className='bg-[#E0CDB0] min-h-screen flex flex-col justify-center items-center space-y-10 px-4 md:px-10 lg:px-20'>
            <p className='font-knickerbockers text-4xl md:text-8xl'>NOS PRODUITS</p>
            <animated.div style={springProps} className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16'>
                <Product1 />
                <Product2 />
                <Product3 />
            </animated.div>
            <a href="#" className='font-ramillas underline text-xl md:text-3xl'>VOIR TOUS LES PRODUITS</a>
        </div>
    );
}

export default NosProduits;
